import React from 'react';
import './App.css';

function titleCase(txt) {
    if(!isNaN(Number(txt.split(" ")[0]))){
      txt = txt.split(" ").slice(1).join(" ");
      console.log(txt);
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

class App extends React.Component {

  state = {
    svgId: null,
    data_color: 1, // 1: monocolor 2:multicolor
    data_style: 1, // 1: outlined 2: filled
    title: titleCase(this.props.uploaded.file.name.replace(new RegExp(this.props.replace_string.replace(/,/g, "|"), 'g'), "").replace(/-/g, " ").replace(/_/g, " ").split(".")[0]).replace(/\s+/g, ' ').trim(),
    tags: ""
  }

  componentDidMount(){
    if(this.props.uploaded.contents.includes("fill")){
      this.setState({data_color: 2, data_style: 2});
    }
    if(this.props.uploaded.contents){
      this.upload(this.props.uploaded.contents).then((res) => {
        this.setState({svgId: res.svgId});
      });
    }
  }

  upload(xml){
    var url = window.location.href.split(":")[0]+"://upload.svgrepo.com/image.php";
    var formData = new FormData();
    formData.append('svg_xml', xml);

    return fetch(url, { method: 'POST', credentials: 'include', body: formData })
    .then(res => res.json());
  }

  saveVector(vector){
    var url = window.location.href.split(":")[0]+"://upload.svgrepo.com/saveVector.php";
    var formData = new FormData();
    formData.append('title', vector.title);
    formData.append('svg_hash', vector.svg_hash);
    formData.append('data_color', this.props.data_color);
    formData.append('data_style', this.props.data_style);
    formData.append('data_pack', this.props.packTitle);
    formData.append('tags', vector.tags);
    formData.append('license', this.props.license);
    formData.append('license_owner', this.props.license_owner);
    formData.append('license_link', this.props.license_link);

    return fetch(url, { method: 'POST', credentials: 'include', body: formData })
    .then(res => res.text());
  }

  componentWillReceiveProps(newProps){
    if(newProps.save == true){
      console.log("saving...", this.state.title, this.props.packTitle)
      this.saveVector({title: this.state.title, svg_hash: this.state.svgId, tags: this.state.tags}).then(res => {
        console.log(res);
      })
    }
  }

  render(){
    return (
      <div className="fragment">
        {this.props.show_vector && <div className="uploadedImage" dangerouslySetInnerHTML={{__html: this.props.uploaded.contents}}></div>}
        <div style={{color: "#8899a4", fontWeight: 500, textAlign: "center"}}>{this.state.svgId ? "Uploaded" : "Uploading..."}</div>
        <div><input type="text" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})}/></div>
        <div><input type="text" placeholder="Tags..." value={this.state.tags} onChange={(e) => this.setState({tags: e.target.value.replace(/ /g, "-").toLowerCase()})}/></div>

      </div>
    );
  }
}

export default App;
