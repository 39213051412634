export function svgParser(svg){
  if(!svg.includes("<svg ")){
    svg = svg.replace("<svg", "<svg ");
  }

  if(svg.includes("</g>")){
    svg = svg.replace(/></g, '>\n\r<');
    svg = svg.replace(new RegExp(`<g>\r\n</g>\r\n`,"g"),``);
  }

  if(!svg.includes("<!--")){
    svg = svg.replace(new RegExp(`<svg `,"g"),`<!--XX-->
<svg `);
  }
  svg = svg.replace(new RegExp(`&lt;!--(.*?)-&gt;`,"g"),"");
  svg = svg.replace(new RegExp(`<!--(.*?)-->`,"g"),"<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->");

  if(svg.includes("enable-background")){
    svg = svg.replace(new RegExp(` style="enable-background(.*?);"`,"g"),"");
  }

  svg = svg.replace(new RegExp(`currentColor`,"g"),`#000000`);
  svg = svg.replace(new RegExp(`x="0px" y="0px"`,"g"),``);
  svg = svg.replace(new RegExp(`="black"`,"g"),`="#000000"`);
  svg = svg.replace(new RegExp(`="#000"`,"g"),`="#000000"`);
  svg = svg.replace(new RegExp(`#000;`,"g"),`#000000;`);
  svg = svg.replace(new RegExp(`="#fff"`,"g"),`="#ffffff"`);
  svg = svg.replace(new RegExp(`#fff;`,"g"),`#ffffff;`);

  if(svg.includes("width")){
    svg = svg.replace(new RegExp(`width="(.*?)px"`,"i"),`width="800px"`);
    svg = svg.replace(new RegExp(`height="(.*?)px"`,"i"),`height="800px"`);
  }else{
    svg = svg.replace(new RegExp(`<svg `,"g"),`<svg height="800px" width="800px" `);
  }

  if(!svg.includes("#") && !svg.includes(`"none"`)){
    svg = svg.replace(new RegExp(`<svg `,"g"),`<svg fill="#000000" `);
  }

  svg = svg.split("</svg>")[0] + "</svg>";

  if(!svg.includes("<?xml ")){
    svg = `<?xml version="1.0" encoding="utf-8"?>` + svg;
  }
  if(!svg.includes(`xmlns="`)){
    svg = svg.replace(new RegExp(`<svg `,"g"),`<svg xmlns="http://www.w3.org/2000/svg" `);
  }


  return svg;
}
