//import makeid from './makeid';
//import { saveAs } from 'file-saver';
import Event from './event'
import { svgParser } from './svgParser'

class Files {


  manipulateSvg(svg){
    let width = 0;
    let height = 0;
    let paddingX = 0;
    let paddingY = 0;

    let svgQuery = svg.split("<svg")[1].split(">")[0];
    svgQuery = "<svg" + svgQuery + ">";

    if(!svgQuery.includes("viewBox")){
      width = svgQuery.split(`width="`)[1].split(`"`)[0];
      height = svgQuery.split(`height="`)[1].split(`"`)[0];
    }else{
      let originalViewBox = svgQuery.split(`viewBox="`)[1].split(`"`)[0];
      let originalViewBoxArray = originalViewBox.split(" ");
      if(originalViewBoxArray.length == 4){
        paddingX = originalViewBoxArray[0];
        paddingY = originalViewBoxArray[1];
        width = originalViewBoxArray[2];
        height = originalViewBoxArray[3];
      }else if(originalViewBoxArray.length == 2){
        width = originalViewBoxArray[0];
        height = originalViewBoxArray[1];
      }
    }

    width = Number(width);
    height = Number(height);

    let canvasSize = 0;
    let biggest = null;

    if(width > height){
      canvasSize = width;
      biggest = "width";
    }else if(width < height){
      canvasSize = height;
      biggest = "height";
    }else if(width == height){
      canvasSize = width;
      biggest = "equal";
    }

    svgQuery = svgQuery.replace(/ height="(.*?)"/g, "")
    .replace(/ width="(.*?)"/g, "")
    .replace(/ viewBox="(.*?)"/g, "");

    let viewBoxReplacer = "";

    if(biggest == "equal"){
      viewBoxReplacer = ` viewBox="${paddingX} ${paddingY} ${canvasSize} ${canvasSize}"`;
    }else if(biggest == "width"){
      viewBoxReplacer = ` viewBox="${paddingX} ${paddingY-(Number(canvasSize - height)/2).toFixed(2)} ${canvasSize} ${canvasSize}"`;
    }else if(biggest == "height"){
      viewBoxReplacer = ` viewBox="${paddingX-(Number(canvasSize - width)/2).toFixed(2)} ${paddingY} ${canvasSize} ${canvasSize}"`;
    }

    svgQuery = svgQuery.replace("<svg", `<svg width="${canvasSize}px" height="${canvasSize}px"${viewBoxReplacer}`);


    svg = svg.replace(/<svg(.*?)>/g, svgQuery).replace(/<!--(.*?)-->/g, "").replace(/<metadata>(.*?)<\/metadata>/g, "");

    return svg;
  }

  listenFileDrop(){
    let dropTimer = 0;
    let root = document.getElementById('root');
    let cover = document.getElementById('cover');

    root.ondragover = function() {
      clearTimeout(dropTimer);
      dropTimer = setTimeout(function() {
          cover.className = "cover";
          return false;
      }, 1000);

      if(cover.className != "cover coverActive"){
        cover.className = "cover coverActive";
      }
      return false;
    };

    cover.ondragleave = function() {
      clearTimeout(dropTimer);
      cover.className = "cover";
      return false;
    };

    root.ondragend = function() {
      clearTimeout(dropTimer);
      cover.className = "cover";
      return false;
    };

    root.ondrop = (e) => {
      clearTimeout(dropTimer);
      e.preventDefault();
      cover.className = "cover";
      if(e.dataTransfer.files.length){
        var files = e.dataTransfer.files;

        for (var i = 0; i < files.length; i++) {
          let file = files[i];

          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = (event) => {
            if(file.name.endsWith(".svg")){
              Event.emit("upload", {file, contents: svgParser(this.manipulateSvg(event.target.result))});
            }else{
              alert("ONLY svg files are supported.");
            }
          };
        }

      }
      return false;
    };

  }
/*
  exportFile(sheetId) {
    try {
        var isFileSaverSupported = !!new Blob;
    } catch (e) {
      alert("Your device doesn't support file saver!");
    }

    return Markdown.getSheetMarkdown(sheetId, true).then((sheet) => {
      let markdownText = sheet.text;

      var blob = new Blob([markdownText], {
        type: "text/plain;charset=utf-8"
      });

      saveAs(blob, sheet.title + '.md');
    });
  }
*/
}

const _files = new Files();
export default _files;
